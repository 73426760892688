import React, {useState } from "react";
import { FormattedMessage } from "react-intl";

const FAQComponent = ({ data,title }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  const faqs = data && data.length > 0 ? data : [];
  console.log("TITLE is ",title)
  return (
    <>
      {faqs.length > 0 && (
        <div className="faq-parent">
          <h2 className="faq-title">
            <img
              src="/assets/images/faq-icon.png"
              width="24px"
              height="24px"
            ></img>{" "}
            <FormattedMessage id="FAQ" />
          </h2>
          <div className="faq-container">
            {faqs.map((faq, index) => (
              <div key={index} className="faq-item">
                <div
                  onClick={() => toggleAnswer(index)}
                  className="faq-question"
                >
                 <h2 style={{fontSize:"20px"}}> <div>{faq.question}</div></h2>
                  <div style={{ alignContent: "center", cursor: "pointer" }}>
                    <img
                      src={`/assets/images/${
                        activeIndex === index ? "minus" : "add"
                      }-square.png`}
                      width="24px"
                      height="24px"
                    ></img>
                  </div>
                </div>
                <h3>
                <div
                  className={`faq-answer ${
                    activeIndex === index ? "show" : ""
                  }`}
                >
                  {faq.answer}
                 
                </div>
                </h3>
              </div>
            ))}
          </div>
        </div>
      )}
       {faqs.length > 0 && <script
          id="my-script2"
          type="application/ld+json"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              "@context": "https://schema.org",
              "@type": "FAQPage",
              mainEntity: faqs?.map((x)=>{
                return {
                  "@type": "Question",
                  name: x.question,
                  acceptedAnswer: {
                    "@type": "Answer",
                    text: x.answer
                  }
                }
              }),
            }),
          }}
        ></script>}
    </>
  );
};

export default FAQComponent;
