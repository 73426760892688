import dynamic from "next/dynamic";
import { useRef, useState } from "react";
import { FreeMode, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// import { useWindowSize } from "api/constants";

// import { useDispatch } from "react-redux";

import { useWindowSize } from "api/commonConstants";

const BlogWidget = dynamic(() => import("components/liveblog/blogWidget"));

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";

const NewsTopbar = dynamic(() =>
  import("../../../components/newsCard/newsTopbar")
);

export default function VisualStoriesHomeWidget({
  visualStoryData,
  liveBlogWidgetData,
  atOlympicsPage = false,
}) {
  console.log("from inside vscomponenet", liveBlogWidgetData);
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  //   const [shortData, setShortData] = useState(shortsData);
  const size = useWindowSize();
  //   const dispatch = useDispatch();
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const [showWidget, setShowWidget] = useState(
    !(
      liveBlogWidgetData[0]?.events?.length <= 2 ||
      liveBlogWidgetData?.length === 0
    )
  );
  const styleLeft =
    liveBlogWidgetData[0]?.events?.length <= 2 ||
    liveBlogWidgetData?.length === 0
      ? { width: "100%" }
      : {};

  const styleRight =
    liveBlogWidgetData[0]?.events?.length <= 2 ? { width: "0%" } : {};

  return (
    <>
      <div
        className="outer-main-conainer"
        style={{
          marginTop: "1.5rem",
          marginBottom: "1.5rem",
          borderRadius: "12px",
        }}
      >
        <div
          className={`${
            atOlympicsPage
              ? "news-card-body-olympic vsContainer"
              : "main-containerTopShow vsContainer"
          }`}
          style={{ flexDirection: "row !important" }}
        >
          <div className="vs-container-left" style={styleLeft}>
            <div className="topShowsTopBar">
              <NewsTopbar
                videoEn={"Visual Stories"}
                videoHn={"विज़ुअल स्टोरीज़"}
                newsIcon={"visual-story"}
                newsBgclassName={"videoShow"}
                newsBgClass={"videoShowTopBar"}
                videoTitleClass={"videoTitle"}
                videoImgClass={"videoImg"}
                videoLinkClass={"videoLink"}
              />
            </div>

            <div className="checkMobileOrNot">
              <div className="scrollbarX" style={{ minHeight: "30.7rem" }}>
                {visualStoryData?.map((data, index) => (
                  <div
                    className="vs-widget-card"
                    key={index}
                    style={{ marginRight: "12px" }}
                  >
                    <a
                      href={`https://www.thelallantop.com${data?.desktop_url}`}
                      index={index}
                    >
                      <div
                        className="vs-widget-img"
                        style={{ width: "200px", position: "relative" }}
                      >
                        <img
                          className="short-slider-img"
                          src={data?.widget_img}
                        />
                      </div>
                      <div class="vs__content__wrap">
                        <h4>{data?.category_title}</h4>
                        <p>
                          <span>{data?.title}</span>
                        </p>
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            </div>

            <div className="checkDesktopOrNot">
              <Swiper
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  400: {
                    slidesPerView: 3,
                  },
                  639: {
                    slidesPerView: 3.5,
                  },
                  865: {
                    slidesPerView: 4,
                  },
                  1000: {
                    slidesPerView: 4,
                  },
                  1200: {
                    slidesPerView: showWidget ? 4 : 5.2,
                  },
                  1350: {
                    slidesPerView: showWidget ? 4 : 5.2,
                  },
                  1400: {
                    slidesPerView: showWidget ? 4 : 6.2,
                  },

                  1500: {
                    slidesPerView: showWidget ? 5 : 6,
                  },
                  1700: {
                    slidesPerView: showWidget ? 5 : 6,
                  },
                }}
                spaceBetween={16}
                freeMode={true}
                // navigation={true}
                pagination={{
                  clickable: true,
                }}
                onInit={(swiper) => {
                  swiper.params.navigation.prevEl = prevRef.current;
                  swiper.params.navigation.nextEl = nextRef.current;
                  console.log(prevRef.current);
                  console.log(nextRef.current);
                  swiper.on("slideChange", () => {
                    setIsBeginning(swiper.isBeginning);
                    setIsEnd(swiper.isEnd);
                  });
                  swiper.navigation.init();
                  swiper.navigation.update();
                }}
                modules={[FreeMode, Navigation]}
                className="mySwiper shorts-slider "
              >
                {visualStoryData?.map((data, index) => (
                  <SwiperSlide key={index} index={index}>
                    <div className="vs-widget-card" index={index}>
                      <a
                        href={`https://www.thelallantop.com${data?.desktop_url}`}
                        index={index}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="vs-widget-img">
                          <img
                            className="short-slider-img"
                            src={data?.widget_img}
                          />
                        </div>
                        <div class="vs__content__wrap">
                          <h4>{data?.category_title}</h4>
                          <p>
                            <span>{data?.title}</span>
                          </p>
                        </div>
                      </a>
                    </div>
                  </SwiperSlide>
                ))}
                {/* <button onClick={() => swiper?.slidePrev()}>previous</button> */}
                {
                  <div
                    className="shorts-slider-button-bwd vs-fwd-slider"
                    style={
                      !isBeginning ? { display: "block" } : { display: "none" }
                    }
                    ref={prevRef}
                  >
                    <span>{"<"}</span>
                  </div>
                }
                {
                  <div
                    className="shorts-slider-button-fwd vs-fwd-slider"
                    style={!isEnd ? { display: "block" } : { display: "none" }}
                    ref={nextRef}
                  >
                    <span>{">"}</span>
                  </div>
                }
              </Swiper>
            </div>
          </div>
          <div className="vs-container-right" style={styleRight}>
            <BlogWidget liveBlogWidgetData={liveBlogWidgetData} />
          </div>
        </div>
      </div>
    </>
  );
}
