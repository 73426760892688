const ReadNewsCardNew = ({ postData }) => {
  var pageToOpen = postData && postData?.permalink?.split(".com")[1];

  console.log("readNewsNEw", postData);
  return (
    <a href={pageToOpen}>
      <div className="news-article-small">
        <div className="news-image" style={{position:"relative"}}>
          <div className="type-article"></div>
          <img
            src={postData?.headingImageUrl + "?width=200"}
            className="article-image"
          />
          <div className="news-type-icon" style={{ color: "white",position:"absolute",bottom:"2px" ,left:"4px"}}>
              {postData.eventStatus === undefined && (
                <i
                  className={`icon-${
                    postData?.type === "text" ||
                    postData.type === "liveblog"
                      ? "cat-read"
                      : postData?.type === "audio"
                      ? "mic"
                      : "play-circle"
                  }`}
                ></i>
              )}
          </div>
        </div>
        <div className="news-text">{postData?.title}</div>
      </div>
    </a>
  );
};
export default ReadNewsCardNew;
